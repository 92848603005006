import {
  arrayUnion,
  doc,
  increment,
  serverTimestamp,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import { useRouter } from "next/router";
import { useUserState } from "../context/userState.context";
import { converter, firestore } from "../lib/firebase";
import * as Sentry from "@sentry/nextjs";
import { useCallback } from "react";
import { Item, Ticket, TicketCount } from "../types/modalTypes";
import {
  isItem,
  isString,
  isTicket,
  isUserAnsweredSurvey,
} from "../types/typeGuard";
import { UserAnsweredSurvey, UserState } from "../types/userTypes";
import { toast } from "react-hot-toast";
import MultipleViewText from "../../components/shared/MultipleViewText";
import { toastTexts } from "../const/toastTexts";

export const useUserStateHooks = () => {
  const router = useRouter();
  const { userState } = useUserState();

  const {
    query: { ver, orgId, course },
  } = router;

  const {
    uid,
    contentStateObject,
    confirmedArray,
    solvedArray,
    itemArray,
    ticketArray,
    withHeading,
    courseCleared,
  } = userState;

  const updateUserState = useCallback(
    (
      action: string,
      content: string | Item | Ticket | UserAnsweredSurvey,
      secondContent?: string
    ): undefined => {
      switch (action) {
        case "confirm":
          if (!isString(content)) return;

          if (confirmedArray.includes(String(content))) return;

          try {
            const ref = doc(
              firestore,
              `users/${uid}/userState/${course}`
            ).withConverter(converter<UserState>());

            const updateData = {
              confirmedArray: arrayUnion(content),
              updatedAt: serverTimestamp(),
            };

            updateDoc(ref, updateData);

            console.log(`%cconfirm ${content} saved`, "color: red");
          } catch (error) {
            Sentry.captureException(error);
            console.log(error);
            toast.error(<MultipleViewText text={toastTexts.保存に失敗MV} />, {
              duration: 7000,
            });
          }
          break;

        case "reachSpot":
          try {
            if (!isString(content)) return;
            if (contentStateObject[content]?.reachedAt) return;
            const ref = doc(
              firestore,
              `users/${uid}/userState/${course}`
            ).withConverter(converter<UserState>());

            const updateData = {
              contentStateObject: {
                ...contentStateObject,
                [content]: {
                  ...contentStateObject[content],
                  index: content,
                  reachedAt: serverTimestamp(),
                },
              },
              updatedAt: serverTimestamp(),
            };

            updateDoc(ref, updateData);

            console.log(`%creachSpot ${content} saved`, "color: red");
          } catch (error) {
            Sentry.captureException(error);
            console.log(error);
            toast.error(<MultipleViewText text={toastTexts.保存に失敗MV} />, {
              duration: 7000,
            });
          }
          break;

        case "solveQuest":
          try {
            if (!isString(content)) return;

            if (solvedArray.includes(content)) return;

            const ref = doc(
              firestore,
              `users/${uid}/userState/${course}`
            ).withConverter(converter<UserState>());

            const updateData = {
              contentStateObject: {
                ...contentStateObject,
                [content]: {
                  ...contentStateObject[content],
                  index: content,
                  solvedAt: serverTimestamp(),
                },
              },
              solvedArray: arrayUnion(content),
              // コースクリア判定
              courseCleared: courseCleared
                ? courseCleared
                : content === "final",
              updatedAt: serverTimestamp(),
            };

            updateDoc(ref, updateData);

            console.log(`%csolveQuest ${content} saved`, "color: red");
          } catch (error) {
            Sentry.captureException(error);
            console.log(error);
            toast.error(<MultipleViewText text={toastTexts.保存に失敗MV} />, {
              duration: 7000,
            });
          }
          break;

        case "gainItem":
          try {
            if (!isItem(content)) return;

            const { index } = content;

            const alreadyGained = itemArray.some((item) => {
              const { index: itemIndex } = item;

              if (itemIndex === index) return true;
            });

            if (alreadyGained) return;

            const { displayConditionArray = {}, ...userGainItem } = content;

            const ref = doc(
              firestore,
              `users/${uid}/userState/${course}`
            ).withConverter(converter<UserState>());

            const updateData = {
              itemArray: arrayUnion(userGainItem),
              updatedAt: serverTimestamp(),
            };

            updateDoc(ref, updateData);

            console.log(
              `%cgainItem ${content.itemNameMV.kanji} saved`,
              "color: red"
            );
          } catch (error) {
            Sentry.captureException(error);
            console.log(error);
            toast.error(<MultipleViewText text={toastTexts.保存に失敗MV} />, {
              duration: 7000,
            });
          }
          break;

        case "gainTicket":
          try {
            if (!isTicket(content)) return;

            const { index } = content;

            const alreadyGained = ticketArray.some((ticket) => {
              const { index: ticketIndex } = ticket;

              if (ticketIndex === index) return true;
            });

            // 獲得済みの場合はここで終了
            if (alreadyGained) return;

            const { displayConditionArray = {}, ...userGainTicket } = content;

            const userRef = doc(
              firestore,
              `users/${uid}/userState/${course}`
            ).withConverter(converter<UserState>());

            const updateUserData = {
              ticketArray: arrayUnion(userGainTicket),
              updatedAt: serverTimestamp(),
            };

            // データベースに配布数を登録
            const ticketCountRef = doc(
              firestore,
              `${ver}/${orgId}/courses/${course}/ticketCount/${index}`
            ).withConverter(converter<TicketCount>());

            const updateCountData = {
              distributed: increment(1),
              updatedAt: serverTimestamp(),
            };

            const batch = writeBatch(firestore);

            batch.update(userRef, updateUserData);
            batch.update(ticketCountRef, updateCountData);

            batch.commit();

            console.log(
              `%cgainTicket ${content.ticketNameMV.kanji} saved`,
              "color: red"
            );
          } catch (error) {
            Sentry.captureException(error);
            console.log(error);
            toast.error(<MultipleViewText text={toastTexts.保存に失敗MV} />, {
              duration: 7000,
            });
          }
          break;

        case "confirmItem":
          try {
            if (!isString(content)) return;

            // 該当アイテムを確認済みに変更
            const newArray = itemArray.map((value) => {
              if (value.index === content) {
                value.isConfirmed = true;
                return value;
              }
              return value;
            });

            const ref = doc(
              firestore,
              `users/${uid}/userState/${course}`
            ).withConverter(converter<UserState>());

            const updateData = {
              itemArray: newArray,
              updatedAt: serverTimestamp(),
            };

            updateDoc(ref, updateData);

            console.log(`%cconfirmItem ${content} saved`, "color: red");
          } catch (error) {
            Sentry.captureException(error);
            console.log(error);
            toast.error(<MultipleViewText text={toastTexts.保存に失敗MV} />, {
              duration: 7000,
            });
          }
          break;

        case "confirmTicket":
          try {
            if (!isString(content)) return;

            // 該当チケットを確認済みに変更
            const newArray = ticketArray.map((value) => {
              if (value.index === content) {
                value.isConfirmed = true;
                return value;
              }
              return value;
            });

            const ref = doc(
              firestore,
              `users/${uid}/userState/${course}`
            ).withConverter(converter<UserState>());

            const updateData = {
              ticketArray: newArray,
              updatedAt: serverTimestamp(),
            };

            updateDoc(ref, updateData);

            console.log(`%cconfirmTicket ${content} saved`, "color: red");
          } catch (error) {
            Sentry.captureException(error);
            console.log(error);
            toast.error(<MultipleViewText text={toastTexts.保存に失敗MV} />, {
              duration: 7000,
            });
          }
          break;

        case "useTicket":
          try {
            if (!isString(content)) return;

            const alreadyUsed = ticketArray.some((ticket) => {
              const { index, isUsed } = ticket;

              if (index === content && isUsed) return true;
            });

            // 使用済みの場合はここで終了
            if (alreadyUsed) return;

            const newArray = ticketArray.map((value) => {
              if (value.index === content) {
                // 稀に拡張不可になる時の対策
                const newValue = Object.assign(
                  {},
                  JSON.parse(JSON.stringify(value))
                );

                newValue.isUsed = true;
                newValue.isUsedAt = new Date();
                return newValue;
              }
              return value;
            });

            const userRef = doc(
              firestore,
              `users/${uid}/userState/${course}`
            ).withConverter(converter<UserState>());

            const updateUserData = {
              ticketArray: newArray,
              updatedAt: serverTimestamp(),
            };

            // データベースに使用数を登録
            const ticketCountRef = doc(
              firestore,
              `${ver}/${orgId}/courses/${course}/ticketCount/${content}`
            ).withConverter(converter<TicketCount>());

            const updateCountData = {
              used: increment(1),
              updatedAt: serverTimestamp(),
            };

            const batch = writeBatch(firestore);

            batch.update(userRef, updateUserData);
            batch.update(ticketCountRef, updateCountData);

            batch.commit();

            console.log(`%cuseTicket ${content} saved`, "color: red");
          } catch (error) {
            Sentry.captureException(error);
            console.log(error);
            toast.error(<MultipleViewText text={toastTexts.保存に失敗MV} />, {
              duration: 7000,
            });
          }
          break;

        case "answerSurvey":
          try {
            if (!isUserAnsweredSurvey(content)) return;

            const ref = doc(
              firestore,
              `users/${uid}/userState/${course}`
            ).withConverter(converter<UserState>());

            const updateData = {
              confirmedArray: arrayUnion(content.index),
              surveyArray: arrayUnion(content),
              updatedAt: serverTimestamp(),
            };

            updateDoc(ref, updateData);

            console.log(
              `%canswerSurvey ${content.surveyName} saved`,
              "color: red"
            );
          } catch (error) {
            Sentry.captureException(error);
            console.log(error);
          }
          break;

        case "changeWithHeading":
          try {
            const ref = doc(
              firestore,
              `users/${uid}/userState/${course}`
            ).withConverter(converter<UserState>());

            const updateData = {
              withHeading: !withHeading,
              updatedAt: serverTimestamp(),
            };

            updateDoc(ref, updateData);

            console.log("%cchange withHeading saved", "color: red");
          } catch (error) {
            Sentry.captureException(error);
            console.log(error);
            toast.error(<MultipleViewText text={toastTexts.保存に失敗MV} />, {
              duration: 7000,
            });
          }
          break;

        case "setMembership":
          if (!isString(content)) return;
          if (!isString(secondContent)) return;

          if (confirmedArray.includes(String(content))) return;

          try {
            const ref = doc(
              firestore,
              `users/${uid}/userState/${course}`
            ).withConverter(converter<UserState>());

            const updateData = {
              withMembership: true,
              membershipType: secondContent,
              membershipId: content,
              membershipPassword: "none",
              updatedAt: serverTimestamp(),
            };

            updateDoc(ref, updateData);

            console.log(`%cset membershipt ${content} saved`, "color: red");
          } catch (error) {
            Sentry.captureException(error);
            console.log(error);
            toast.error(<MultipleViewText text={toastTexts.保存に失敗MV} />, {
              duration: 7000,
            });
          }
          break;

        case "reset":
          try {
            const ref = doc(
              firestore,
              `users/${uid}/userState/${course}`
            ).withConverter(converter<UserState>());

            const updateData = {
              confirmedArray: ["validUrlLogin"],
              courseCleared: false,
              contentStateObject: {},
              solvedArray: [],
              ticketArray: [],
              itemArray: [],
              surveyArray: [],
              withHeading: true,
              withReseted: true,
              withMembership: false,
              membershipType: "",
              membershipUid: "",
              membershipId: "",
              membershipPassword: "",
              updatedAt: serverTimestamp(),
            };

            updateDoc(ref, updateData);

            console.log("%creset saved", "color: red");
          } catch (error) {
            Sentry.captureException(error);
            console.log(error);
            toast.error(<MultipleViewText text={toastTexts.保存に失敗MV} />, {
              duration: 7000,
            });
          }
          break;
      }
    },
    [
      confirmedArray,
      contentStateObject,
      course,
      courseCleared,
      itemArray,
      orgId,
      solvedArray,
      ticketArray,
      uid,
      ver,
      withHeading,
    ]
  );

  return { updateUserState };
};

export const useUserQuestStateCheck = () => {
  const router = useRouter();
  const { userState } = useUserState();

  const {
    query: { ver, orgId, course, room },
  } = router;

  const { contentStateObject } = userState;

  const userQuestStateCheck = useCallback(
    (content: string): string => {
      const { reachedAt, solvedAt } = contentStateObject[content] || {};

      if (reachedAt && solvedAt)
        return `/${ver}/${orgId}/${course}/${room}/${content}/prize`;

      if (reachedAt && !solvedAt)
        return `/${ver}/${orgId}/${course}/${room}/${content}/quest`;

      return "";
    },
    [contentStateObject, course, orgId, room, ver]
  );

  return { userQuestStateCheck };
};
