export const qrCheckList: { [key: string]: string } = {
  content1: "7dgxg6ryrA1kZxMUpe6pEK1K3BzvuxcHei6X3kTJHFU=",
  content2: "03NpOnWtY6SujDS8x5Q3Oe4JzbSYWNYYEcQdvrlwSaa=",
  content3: "SqEHLYAS5vIT0FhjwkxkhKfItqIBtNOkDR0gu3FZJfQ=",
  content4: "N7aSzTWO3kptVeMkF8WUkxUY7fDQy6Tgj3yzDVPXSpo=",
  content5: "XsIrye0GPLua6tFhuyyxInCM8djVtpSifqn6Rsetgko=",
  final: "u2G19fVWYVSpQsb7PnLlw3pp4AFShnMgbIQ7ez6bSqk=",
};

export const qrEmergencyCheckList: { [key: string]: string } = {
  content1: "鶴の間",
  content2: "北星の間",
  content3: "TSUBAKI",
  content4: "ポラリス",
  // content5: "ベビーケアルーム",
  final: "万葉の間",
};
