import { HowToPlay } from "../types/modalTypes";

export const howToPlayData: HowToPlay[] = [
  {
    ver: "v1",
    orgId: "newotanisapporo",
    course: "nazotoki",
    room: "room1",
    index: "howToPlay1",
    type: "default",
    textMVArray: [
      {
        kanji:
          "このホテルにある<span class='font-bold text-red-500'>何か</span>が怪盗のターゲットになっていることがわかった！",
        furigana: "",
      },
      {
        kanji:
          "ホテルの各スポットを調査し、情報を集め、怪盗の狙いを看過して守り抜こう！",
        furigana: "",
      },
      {
        kanji:
          "ホテルにある様々な<span class='font-bold text-red-500'>アイテム</span>を持っているので謎解きに役立ててね。",
        furigana: "",
      },
      {
        kanji:
          "1つでも謎を解いて情報を集めた人には、様々な特典が使えるチケットをプレゼント。すべての謎を解くと<span class='font-bold text-red-500'>素敵な抽選</span>の応募券がもらえるよ！",
        furigana: "",
      },
      {
        kanji: "制限時間はないからじっくり考えてね。",
        furigana: "",
      },
      {
        kanji:
          "困った時はメニューの中にあるヘルプを確認してね！ヒントもあるよ。",
        furigana: "",
      },
      {
        kanji:
          "クリア履歴は保存することができるよ。これも詳しくはメニューの中のヘルプを見てね。",
        furigana: "",
      },
    ],
  },
];
