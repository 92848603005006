import { Answer } from "../types/questTypes";

export const answerData: Answer[] = [
  {
    ver: "v1",
    orgId: "newotanisapporo",
    course: "nazotoki",
    room: "room1",
    content: "content1",
    index: "answer1",
    answerArray: ["まよなか"],
    questHintMVArray: [
      {
        kanji:
          "手持ちのアイテムを確認しよう。1や8などを変換できそうなアイテムはないかな？",
        furigana: "",
      },
    ],
    explanation:
      "手持ちのアイテムに「ホテルの定規」があります。アイテム画面で「アイテムを使う」を選択すると謎解きの画面に定規が現れ、それぞれの正方形を測ることができます。1cm、9cm、8cm、2cmの正方形が見つかるので、書かれているひらがなをつなげて「まよなか」が答え（決行時刻）となります。",
  },
  {
    ver: "v1",
    orgId: "newotanisapporo",
    course: "nazotoki",
    room: "room1",
    content: "content2",
    index: "answer2",
    answerArray: ["いつかご"],
    questHintMVArray: [
      {
        kanji:
          "建物の外にある彫刻「なんもさストーブ」を調べよう。手持ちのアイテムで光を当てて、刻まれた年を探すべし。",
        furigana: "",
      },
    ],
    explanation:
      "なんもさストーブには「1868」の数字が刻まれています。合わせて、この手がかりは怪しいカードと書かれており、手持ちのアイテム「ホテルの懐中電灯」を使うと、透かしを見ることができます。透かしの中に「1868」の並びがあり、そこに対応する文字を読むと「いつかご」が答え（決行予定日）となります。",
  },
  {
    ver: "v1",
    orgId: "newotanisapporo",
    course: "nazotoki",
    room: "room1",
    content: "content3",
    index: "answer3",
    answerArray: ["ひとつ", "いち", "1つ", "１つ"],
    questHintMVArray: [
      {
        kanji:
          "手持ちのアイテムを使って、隅々まで隈なく調べよう。どこかにローマ字が書かれているよ。",
        furigana: "",
      },
    ],
    explanation:
      "手持ちのアイテムに「ホテルの虫眼鏡」があります。虫眼鏡を使うと写真が拡大表示されます。実物と違い、左下にこっそりアルファベットが追加されています。ぱっと見で読むのは難しいですが、追加されたアルファベットの上にある線が矢印になっており、矢印の向きに読むと「nusumukosuuhahitotsu（盗む個数は1つ）と書かれています。",
  },
  {
    ver: "v1",
    orgId: "newotanisapporo",
    course: "nazotoki",
    room: "room1",
    content: "content4",
    index: "answer4",
    answerArray: ["芸術作品"],
    questHintMVArray: [
      {
        kanji:
          "このままでは読むのは難しい。手持ちのアイテムを使って左右を反転させよう。",
        furigana: "",
      },
    ],
    explanation:
      "手持ちのアイテムに「ホテルの手鏡」があります。手鏡を使うと、手掛かりの画像が左右反転して表示されます。白い線で書かれたカタカナとして見ることができます。「コンカイノターゲットハゲイジュツサクヒン（今回のターゲットは芸術作品）」と読むことができます。",
  },
  // {
  //   ver: "v1",
  //   orgId: "newotanisapporo",
  //   course: "nazotoki",
  //   room: "room1",
  //   content: "content5",
  //   index: "answer5",
  //   answerArray: ["オクジョウ"],
  //   questHintMVArray: [
  //     {
  //       kanji: "カタカナ5文字が隠れているよ。1文字目は「オ」。",
  //       furigana: "",
  //     },
  //   ],
  //   explanation:
  //     "星が並んでいる3枚の画像がアニメーションで順番に表示されています。全てを重ねてカタカナの文字を作ると「オクジョウ」が答えになります。",
  // },
  // {
  //   ver: "v1",
  //   orgId: "newotanisapporo",
  //   course: "nazotoki",
  //   room: "room1",
  //   content: "content6",
  //   index: "answer6",
  //   answerArray: ["れきし"],
  //   questHintMVArray: [
  //     {
  //       kanji:
  //         "もみじの落ち葉がつくと「か」が「き」に、「は」が「ひ」になっているね。何か法則がありそうだ。他の落ち葉にもそれぞれ法則があるよ。",
  //       furigana: "",
  //     },
  //   ],
  //   explanation: "",
  // },
  {
    ver: "v1",
    orgId: "newotanisapporo",
    course: "nazotoki",
    room: "room1",
    content: "final",
    index: "answer7",
    answerArray: ["北海道の鳥たち"],
    questHintMVArray: [
      {
        kanji:
          "カードを並び替え、作品カードの上下にある文字を完成させて読もう！",
        furigana: "",
      },
      {
        kanji:
          "ニューオータニのマークはどれだろう？カードを並び替えてそのマークを4つ組み立てよう。手持ちのアイテムが最後まで役に立つよ。",
        furigana: "",
      },
    ],
    explanation: "",
  },
];
