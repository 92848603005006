import { Popover } from "@headlessui/react";
import { buttonTexts } from "../../../../common/const/buttonTexts";
import { divTexts } from "../../../../common/const/divTexts";
import { useUserStateHooks } from "../../../../common/hooks/userStateHooks";
import { UserGainedTicket } from "../../../../common/types/userTypes";
import ImageComponent from "../../../shared/ImageComponent";
import MultipleViewText from "../../../shared/MultipleViewText";
import PopoverPanel from "../../../shared/PopoverPanel";
import { useTicketState } from "../../hooks/modalHooks";
import styles from "../ModalContents.module.scss";

interface Props {
  userGainedTicket: UserGainedTicket;
}

const OnlyOnce: React.FC<Props> = ({ userGainedTicket }) => {
  const { updateUserState } = useUserStateHooks();
  const { ticketNameMV, ticketImageUrl, ticketTextMVArray, index } =
    userGainedTicket;

  const { outOfStock, isUsable, setIsUsable } =
    useTicketState(userGainedTicket);

  return (
    <div className={`${styles.modalInner} ${styles.gainItem}`}>
      <div className={styles.itemName}>
        <div className={styles.itemNameInner}>
          <MultipleViewText text={ticketNameMV} />
        </div>
      </div>
      <div className={`${styles.imgWrap}`}>
        <div className={styles.imgInner}>
          <div className={styles.img}>
            <ImageComponent
              url={ticketImageUrl}
              alt={ticketNameMV?.kanji}
              width={400}
              height={200}
            />
          </div>
        </div>
      </div>
      <div className="scrollbar scrollbar-thumb-rounded scrollbar-thumb-black mt-4 h-[250px] overflow-y-scroll">
        <div className={styles.text}>
          <div className="my-1 mt-2 text-center text-base font-bold">
            【特典内容】
          </div>
          <p className="mb-2 pt-1 font-bold">
            以下の特典から1つ選ぶことができます。
          </p>
          <p className="text-base">
            ①朝食料金割引券 2,400円→2,200円（1F ランデブーラウンジ）
          </p>
          <p className="mt-2 text-base">
            ②レストラン10%割引券（1F ランデブーラウンジもしくはB1
            フォーシーズンで利用可能 プライベート商品の購入も可）
          </p>
          <p className="mt-2">
            ※日曜、月曜定休(朝食ビュッフェは毎日開催しております)
            <br />
            ※10%割引券は朝食にはご利用いただけません。
            <br />
            ※使用期限は2024/9/30 24:00までです。
          </p>
        </div>
        <div>
          <div className="my-3 text-center text-base font-bold">
            【使用方法】
          </div>
          <p className="px-4 text-sm">
            お会計時「挑戦記念チケット」の画面をスタッフへ掲示ください。
            <br />
            スタッフにて使用済みにする画面操作をさせていただきます。
          </p>
        </div>
        {/* チケットの残数情報取得までは灰色の明滅、取得後は状況に応じてボタンかテキストを表示 */}
        {isUsable === null ? (
          <div className="h-9 animate-pulse bg-slate-400" />
        ) : (
          <div className={styles.btnUseWrap}>
            {isUsable ? (
              <PopoverPanel
                bgColor=""
                buttonText={buttonTexts.使用するMV}
                className={styles.btnUse}
              >
                <div className={styles.popupBox}>
                  <div>
                    <MultipleViewText text={divTexts.この操作はMV} />
                  </div>
                  <div className={styles.btns}>
                    <button
                      type="button"
                      onClick={() => {
                        updateUserState("useTicket", index);
                        setIsUsable(false);
                      }}
                      className={`${styles.btnNext} heartbeat`}
                      onTouchStart={() => {
                        return {};
                      }}
                    >
                      <MultipleViewText text={buttonTexts.進めるMV} />
                    </button>
                    <Popover.Button className="">
                      <MultipleViewText text={buttonTexts.閉じるMV} />
                    </Popover.Button>
                  </div>
                </div>
              </PopoverPanel>
            ) : outOfStock ? (
              <div className={styles.done}>
                <MultipleViewText text={divTexts.先着に達したためMV} />
              </div>
            ) : (
              <div className={styles.done}>
                <MultipleViewText text={divTexts.使用済みMV} />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default OnlyOnce;
