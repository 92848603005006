import { Ticket } from "../types/modalTypes";

export const ticketData: Ticket[] = [
  {
    ver: "v1",
    orgId: "newotanisapporo",
    course: "nazotoki",
    room: "room1",
    content: "everywhere",
    type: "onlyOnce",
    index: "ticket1",
    indexNumber: 1,
    priority: 50,
    distributeStock: 0,
    usableStock: 0,
    ticketNameMV: {
      kanji: "挑戦記念チケット",
      furigana: "",
    },
    ticketImageUrl: "/images/ticket/nazotoki/ticket01",
    ticketTextMVArray: [
      {
        kanji:
          "謎を1つ解けた記念のチケットです。様々な特典があります。メニューのチケットから確認してね。",
        furigana: "",
      },
    ],
    displayConditionArray: [
      {
        url: "",
        type: "includesAndLength",
        direction: "greater",
        item: "solvedArray",
        targetValueArray: ["content1", "content2", "content3", "content4"],
        targetNumber: 0,
      },
    ],
  },

  {
    ver: "v1",
    orgId: "newotanisapporo",
    course: "nazotoki",
    room: "room1",
    content: "everywhere",
    type: "anytime",
    index: "ticket2",
    indexNumber: 2,
    priority: 40,
    distributeStock: 0,
    usableStock: 0,
    ticketNameMV: {
      kanji: "クリアの証チケット",
      furigana: "",
    },
    ticketImageUrl: "/images/ticket/nazotoki/ticket02",
    ticketTextMVArray: [
      {
        kanji:
          "クリアの証です。素敵な抽選に応募できるよ！メニューのチケットから確認してね。",
        furigana: "",
      },
    ],
    displayConditionArray: [
      {
        url: "",
        type: "includes",
        direction: "true",
        item: "solvedArray",
        targetValueArray: ["final"],
        targetNumber: 0,
      },
    ],
  },
];
