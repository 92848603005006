import { PrivacyPolicy } from "../types/modalTypes";

export const privacyPolicyData: PrivacyPolicy[] = [
  {
    ver: "v1",
    orgId: "newotanisapporo",
    course: "nazotoki",
    type: "default",
    textMVArray: [
      {
        kanji:
          "トムソーヤ合同会社（以下「当社」という）は、当社の提供する宝探しコンテンツの利用者に関する個人情報を含んだ情報（以下「ユーザー情報」という）の取扱いについて、以下のとおりプライバシーポリシー（以下「本ポリシー」という）を定めます。",
        furigana: "",
      },
      {
        kanji: "1.情報を取得するコンテンツ提供者\nトムソーヤ合同会社",
        furigana: "",
      },
      {
        kanji:
          "2.取得するユーザー情報と目的\n本コンテンツで取得するユーザー情報と目的は以下のとおりです。",
        furigana: "",
      },
      {
        kanji:
          "(1)メールアドレス\n取得方法:アカウント登録時、利用者の入力による取得。メールアドレスが必要なアカウント登録方法を選択していてかつご入力をいただけない場合、本コンテンツの一部をご利用いただけない場合がございます。\n利用目的:利用者の識別。",
        furigana: "",
      },
      {
        kanji:
          "(2)電話番号\n取得方法:アカウント登録時、利用者の入力による取得。電話番号が必要なアカウント登録方法を選択していてかつご入力をいただけない場合、本コンテンツの一部をご利用いただけない場合がございます。\n利用目的:利用者の識別。",
        furigana: "",
      },
      {
        kanji:
          "(3)位置情報\n取得方法:利用者による取得の承認後、自動取得。承認をいただけない場合、本コンテンツの一部をご利用いただけない場合がございます。\n利用目的：本コンテンツ内でのクエストスポット到達のクリア判定。",
        furigana: "",
      },

      {
        kanji:
          "(4)その他\nお問い合わせに対する調査、返答、及びご本人確認のために、電話番号、メールアドレス、端末機種名、OS情報等を取得することがあります。",
        furigana: "",
      },

      {
        kanji: "3.通知・公表または同意取得の方法・利用者関与の方法",
        furigana: "",
      },

      {
        kanji:
          "(1)通知・公表\n当社は、本ポリシーに関する通知・公表は本コンテンツに掲載する方法で行います。",
        furigana: "",
      },

      {
        kanji:
          "4.適用範囲\n本ポリシーは、本コンテンツのみに適用されます。本コンテンツからリンクする当社以外の事業者の外部URLアドレス、または個人のURLアドレス等における利用者情報の取り扱いについては、当社は責任を負いかねます。",
        furigana: "",
      },
      {
        kanji: "5.外部送信・第三者提供",
        furigana: "",
      },
      {
        kanji:
          "(1)外部送信\n取得したユーザー情報については、当社が管理を委託するホスティング会社のサーバーに送信されます。",
        furigana: "",
      },
      {
        kanji:
          "(2)第三者提供\n当社は、取得したユーザー情報について、以下の場合第三者に提供することがあります。\n・統計的なデータなどユーザーを識別することができない状態で提供する場合\n・法令に基づき開示・提供を求められた場合",
        furigana: "",
      },
      {
        kanji:
          "6.お問い合わせ\n本ポリシーに関する、ご意見、ご質問、苦情の申出その他ユーザー情報の取扱いに関するお問い合わせは、以下の窓口にご連絡ください。\nMail:info@tom-sawyer.co.jp",
        furigana: "",
      },
      {
        kanji:
          "7.改定\n当社は、当社の裁量に基づいて、本ポリシーを変更します。本ポリシーを変更する場合、変更後の本ポリシーの効力発生日および内容を当社ウェブサイト上での掲示その他の適切な方法により周知し、または利用者に通知します。当社は、変更後の利用規約の効力発生日以降に利用者が本サービスを利用したときは、利用者は、利用規約の変更に同意したものとみなします。ただし、法令上利用者の同意が必要となるような内容の変更の場合は、当社所定の方法で利用者の同意を得るものとします。",
        furigana: "",
      },

      {
        kanji: "8.制定日・改定日\n制定:2022年11月1日",
        furigana: "",
      },
    ],
  },
];
