import { TextMV } from "../types/commonTypes";

export const divTexts: { [key: string]: TextMV } = {
  獲得したアイテムはありませんMV: {
    kanji: "獲得したアイテムはありません",
    furigana: '<ruby data-ruby="かくとく">獲得</ruby>したアイテムはありません',
  },
  獲得したチケットはありませんMV: {
    kanji: "獲得したチケットはありません",
    furigana: '<ruby data-ruby="かくとく">獲得</ruby>したチケットはありません',
  },
  クエストが出題されている時はMV: {
    kanji: "クエストが出題されている時は、ここにヒントが表示されます。",
    furigana:
      'クエストが<ruby data-ruby="しゅつだい">出題</ruby>されている<ruby data-ruby="とき">時</ruby>は、ここにヒントが<ruby data-ruby="ひょうじ">表示</ruby>されます。',
  },
  当コンテンツはMV: {
    kanji:
      "当コンテンツはPCやタブレットには対応しておりません。スマートフォンからご参加ください。",
    furigana:
      '<ruby data-ruby="とう">当</ruby>コンテンツはPCやタブレットには<ruby data-ruby="たいおう">対応</ruby>しておりません。スマートフォンからご<ruby data-ruby="さんか">参加</ruby>ください。',
  },
  この操作はMV: {
    kanji:
      "この操作は取り消せません。スタッフの指示に従って使用済みにしてください。進めてよろしいですか？",
    furigana:
      'この<ruby data-ruby="そうさ">操作</ruby>は<ruby data-ruby="と">取</ruby>り<ruby data-ruby="け">消</ruby>せません。スタッフの<ruby data-ruby="しじ">指示</ruby>に<ruby data-ruby="したが">従</ruby>って<ruby data-ruby="しよう">使用</ruby><ruby data-ruby="ず">済</ruby>みにしてください。<ruby data-ruby="すす">進</ruby>めてよろしいですか？',
  },
  使用済みMV: {
    kanji: "使用済み",
    furigana:
      '<ruby data-ruby="しよう">使用</ruby><ruby data-ruby="ず">済</ruby>み',
  },
  先着に達したためMV: {
    kanji:
      "このチケットは先着の制限数に達したため、恐れ入りますがもうご使用いただけません。",
    furigana: "",
  },
  // まずは最低XX箇所のクエストをMV: {
  //   kanji:
  //     "まずは最低XX箇所のクエストをクリアしよう！マップで好きな所を選んでピンをタップしてね。",
  //   furigana: "",
  // },
  // 最後のクエストがMV: {
  //   kanji: "ついに最後のクエストが現れた！マップ上で選択して挑戦しよう！",
  //   furigana: "",
  // },
  // クリアおめでとうMV: {
  //   kanji: "コースクリアおめでとう！手に入れたチケットを確認してね！",
  //   furigana: "",
  // },

  // …………………………………………………
  まずは最低XX箇所のクエストをMV: {
    kanji:
      "まずはXX個の謎を解こう！4つのスポットにあるQRコードを見つけ出して謎に挑戦してね。",
    furigana: "",
  },
  最後のクエストがMV: {
    kanji:
      "ついに最後の謎に挑戦できるようになった！メインルームから挑戦しよう！",
    furigana: "",
  },
  クリアおめでとうMV: {
    kanji: "クリアおめでとう！手に入れたチケットを確認してね！",
    furigana: "",
  },
  // …………………………………………………

  クリア済みMV: {
    kanji: "クリア済み",
    furigana: "",
  },
  アイテム獲得数MV: {
    kanji: "アイテム獲得数",
    furigana: "",
  },
  チケット獲得数MV: {
    kanji: "チケット獲得数",
    furigana: "",
  },
  このアカウントはMV: {
    kanji: "※このアカウントは登録済みです。",
    furigana: "",
  },
  登録時に使用したメールアドレスをMV: {
    kanji: "登録時に使用したメールアドレスを入力して送信してください",
    furigana: "",
  },
  IDとパスワードを決めてMV: {
    kanji: "IDとパスワードを決めて入力し、登録してください。",
    furigana: "",
  },
  IDとパスワードを紛失したMV: {
    kanji:
      "※ID/パスワードを紛失した場合、復元を承ることはできません。取り扱いにご注意ください。",
    furigana: "",
  },
  IDとパスワードを入力MV: {
    kanji: "IDとパスワードを入力して送信してください。",
    furigana: "",
  },
};

export const divErrorTexts = {
  ご使用の端末のMV: {
    kanji:
      "ご使用の端末のGPS機能をオンにできなかったため、クエストを開始できません。GPS機能の使用を許可しなかったか、ブラウザ単位で位置情報の使用を許可していないことが考えられます。スマートフォンの設定をご確認ください。\n画面オーバーレイに関するエラーが出ている場合は「（端末名） 画面オーバーレイ エラー」などで検索してみてください。",
    furigana:
      'ご<ruby data-ruby="しよう">使用</ruby>の<ruby data-ruby="たんまつ">端末</ruby>のGPS<ruby data-ruby="きのう">機能</ruby>をオンにできなかったため、クエストを<ruby data-ruby="かいし">開始</ruby>できません。GPS<ruby data-ruby="きのう">機能</ruby>の<ruby data-ruby="しよう">使用</ruby>を<ruby data-ruby="きょか">許可</ruby>しなかったか、ブラウザ<ruby data-ruby="たんい">単位</ruby>で<ruby data-ruby="いち">位置</ruby><ruby data-ruby="じょうほう">情報</ruby>の<ruby data-ruby="しよう">使用</ruby>を<ruby data-ruby="きょか">許可</ruby>していないことが<ruby data-ruby="かんが">考</ruby>えられます。スマートフォンの<ruby data-ruby="せってい">設定</ruby>をご<ruby data-ruby="かくにん">確認</ruby>ください。\n<ruby data-ruby="がめん">画面</ruby>オーバーレイに<ruby data-ruby="かん">関</ruby>するエラーが<ruby data-ruby="で">出</ruby>ている<ruby data-ruby="ばあい">場合</ruby>は「（<ruby data-ruby="たんまつめい">端末名</ruby>） <ruby data-ruby="がめん">画面</ruby>オーバーレイ エラー」などで<ruby data-ruby="けんさく">検索</ruby>してみてください。',
  },
  システムエラーがMV: {
    kanji:
      "システムエラーが発生したか、または位置情報の取得に時間がかかりすぎてエラーが発生しました。恐れ入りますが電波状況の良いところで、画面を再読み込みの上再度お試しください。",
    furigana:
      'システムエラーが<ruby data-ruby="はっせい">発生</ruby>したか、または<ruby data-ruby="いち">位置</ruby><ruby data-ruby="じょうほう">情報</ruby>の<ruby data-ruby="しゅとく">取得</ruby>に<ruby data-ruby="じかん">時間</ruby>がかかりすぎてエラーが<ruby data-ruby="はっせい">発生</ruby>しました。<ruby data-ruby="おそ">恐</ruby>れ<ruby data-ruby="い">入</ruby>りますが<ruby data-ruby="でんぱ">電波</ruby><ruby data-ruby="じょうきょう">状況</ruby>の<ruby data-ruby="よ">良</ruby>いところで、<ruby data-ruby="がめん">画面</ruby>を<ruby data-ruby="さい">再</ruby><ruby data-ruby="よ">読</ruby>み<ruby data-ruby="こ">込</ruby>みの<ruby data-ruby="うえ">上</ruby><ruby data-ruby="さいど">再度</ruby>お<ruby data-ruby="ため">試</ruby>しください。',
  },
};
