import { UserGainedItem } from "../../../../common/types/userTypes";
import ImageComponent from "../../../shared/ImageComponent";
import MultipleViewText from "../../../shared/MultipleViewText";
import styles from "../ModalContents.module.scss";
import { useRecoilState } from "recoil";
import { QuestCommandArrayState } from "../../../../common/recoil/questAtoms";
import { useModal } from "../../hooks/modalHooks";
import { DisplayMenuState } from "../../../../common/recoil/menuAtoms";
import { useRouter } from "next/router";
import toast from "react-hot-toast";

interface Props {
  userGainedItem: UserGainedItem;
}

const Default: React.FC<Props> = ({ userGainedItem }) => {
  const { itemNameMV, itemImageUrl, itemTextMVArray, indexNumber } =
    userGainedItem;
  const [, setIsVisible] = useModal("infoItem");

  const [, setQuestCommandArray] = useRecoilState(QuestCommandArrayState);
  const [, setDisplayMenu] = useRecoilState(DisplayMenuState);

  const router = useRouter();

  const { asPath } = router;

  // useEffect(() => {
  //   // if (solvedArray.length < 5 && command.length === 3 && indexNumber !== 2) {
  //   //   toast.success("特に何も起こらなかった");
  //   //   setCommand([]);
  //   // }

  //   if (solvedArray.length > 5 && command.length === 3 && indexNumber === 2) {
  //     const ref = doc(
  //       firestore,
  //       `users/${uid}/userState/${course}`
  //     ).withConverter(converter<UserState>());

  //     const updateData = {
  //       contentStateObject: {
  //         ...contentStateObject,
  //         content7: {
  //           index: "content7",
  //           solvedAt: serverTimestamp(),
  //         },
  //       },
  //       solvedArray: arrayUnion("content7"),
  //       updatedAt: serverTimestamp(),
  //     };

  //     updateDoc(ref, updateData);

  //     toast.success(
  //       "最後の謎の画面に解答欄が現れた！3階にある巨大な扉を実際に開けよ！床に現れし暗号を「6715324の順」で解読し答えとして解答欄に入力せよ！",
  //       { duration: 10000 }
  //     );
  //     setCommand([]);
  //   }

  //   if (solvedArray.length < 6 && command.length === 1) {
  //     setCommand([]);
  //   }
  // }, [
  //   command.length,
  //   contentStateObject,
  //   course,
  //   indexNumber,
  //   solvedArray.length,
  //   uid,
  // ]);

  return (
    <div className={`${styles.modalInner} ${styles.gainItem}`}>
      <div className={styles.itemName}>
        <div className={styles.itemNameInner}>
          <MultipleViewText text={itemNameMV} />
        </div>
      </div>
      <div className={`${styles.imgWrap} ${styles.item}`}>
        <div className={styles.imgInner}>
          <ImageComponent
            url={itemImageUrl}
            alt={itemNameMV?.kanji}
            width={400}
            height={400}
            className="drop-shadow-md"
          />
        </div>
      </div>
      <div className={styles.text}>
        {itemTextMVArray.map((itemTextMV, index) => {
          if (index === 0) return <div key={index} />;

          return (
            <div key={itemTextMV?.kanji + index}>
              <MultipleViewText text={itemTextMV} />
            </div>
          );
        })}
      </div>
      <div className={styles.btnUse}>
        <button
          onClick={() => {
            if (!asPath.includes("quest")) {
              toast.error("謎解き画面でのみ使用できます。");
              return;
            }

            setQuestCommandArray([indexNumber.toString()]);
            setIsVisible(false);
            setDisplayMenu("");
          }}
        >
          アイテムを使う
          <div className={styles.icon}>
            <ImageComponent
              url="/images/icon/default/icon_use01"
              alt="アイテムを使う"
              width={39}
              height={48}
            />
          </div>
        </button>
      </div>
    </div>
  );
};

export default Default;
