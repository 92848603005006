import { useRouter } from "next/router";
import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { AuthCheckTypeState } from "../../common/recoil/templateAtoms";
import { useCheckPath } from "./hooks/sharedHooks";

const DisplayAuthCheck: React.FC = () => {
  const { checkPath } = useCheckPath();
  const router = useRouter();
  const authCheckType = useRecoilValue(AuthCheckTypeState);

  useEffect(() => {
    if (authCheckType) {
      const path = checkPath(authCheckType, "redirect");

      if (path && !router.asPath.includes(path)) {
        router.push(path);
      }
    }
  }, [authCheckType, checkPath, router]);

  return null;
};

export default DisplayAuthCheck;
